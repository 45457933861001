.full-article-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin: auto;
    width: 50vw;
    flex-direction: column;
    font-size: larger;
}

.image{
    width:50%;
}

.container{
    width: inherit;
}

.container img{
    width: 50%;
}

.topics-container{
    width:inherit;
    margin-bottom: 7vh;
    margin-top: 5vh;
}

.topics-bullets-container{
    width:inherit;
    display: flex;
    flex-wrap: wrap;
    height: inherit;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.topics-bullet{
    background: #e3cffa;
    width: 20vw;
    height: 5vh;
    border-radius: 50px;
    margin: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.arrow {
    border: solid black;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 9px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

@media screen and (max-device-width: 420px) {
    .image{
        width:90%;
    }

    .full-article-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        width: 90vw;
        flex-direction: column;
    }

    .container img{
        width: 80vw
    }

    .topics-container{
        width:inherit;
        height: auto;
        margin-bottom: 7vh;
    }

    .topics-bullets-container{
        width:inherit;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .topics-bullet{
        background: #e3cffa;
        width: 90vw;
        height: 11vw;
        border-radius: 50px;
        margin: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}